module.exports = {
  name: 'Rummage',
  shortName: 'Rummage',
  description: 'May you catch something that will delight your day',
  url: 'https://rummage.haocen.org', // Domain of your site without prefix!
  prefix: '',
  author: 'Haocen Xu',
  twitter: '', // Link to your twitter account, leave empty to hide
  facebook: '', // Link to your facebook account, leave empty to hide
}

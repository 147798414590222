// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"
import "typeface-nunito"
import "typeface-alegreya"

import { name, author } from "./siteConfig"

export const onInitialClientRender = () => {
  console.log(
    `\n %c ${name} © ${author} ${new Date().getFullYear()} %c ${
      window.location.protocol
    }//${window.location.hostname} %c\n\n`,
    "color: #ffce99; background: #000000; padding:5px 0;",
    "color: #000000; background: #ffce99; padding:5px 0;",
    "padding:5px 0;"
  )
}
